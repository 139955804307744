:root {
  --background-color-white: E5E5E5;
  --text-color-black: #000000;
}

.flex-al-jc-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex {
  display: flex;
}

.align-item-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}
