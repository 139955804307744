@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@200;300;400;500;600;700;800&display=swap");
@import url(./Utils.css);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Heebo", sans-serif;
  background-color: var(--background-color-white);
  color: var(--text-color-black);
}

html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}
