.container {
  display: flex;
  flex-direction: column;
}

.container-dark {
  background-color: #313449;
  color: white;
}

.container-light {
  background-color: transparent;
}

.home {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}

.hero-div-wrapper {
  width: 1200px;
  flex-direction: row;
}

.hero-div {
  width: 70%;
  height: 100%;
  padding: 5rem 10rem;
  flex-direction: column;
}

.hero-div h1 {
  width: 100%;
  font-size: 5rem;
  font-weight: 700;
  color: #21243d;
  margin: 0 0 5rem 0;
  line-height: 7rem;
}

.Typewriter__wrapper {
  color: rgb(8, 8, 159);
}

.Typewriter__cursor {
  color: rgb(6, 6, 132);
}

.container-dark .Typewriter__cursor,
.container-dark .Typewriter__wrapper {
  color: rgb(255 0 52);
}

.hero-div p {
  width: 90%;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  margin: 0 0 5rem 0;
  letter-spacing: 1px;
}

.container-dark .hero-div p {
  color: rgb(216, 214, 214);
}

.hero-div button {
  cursor: pointer;
  padding: 1.5rem 0.4rem;
  width: 24rem;
  background-color: rgb(2, 2, 120);
  border-radius: 2px;
  font-weight: 500;
  font-size: 17px;
  line-height: 29px;
  color: #ffffff;
  transition: 0.4s ease-in-out;
  border: none;
  letter-spacing: 0.2rem;
}

.hero-div button:hover {
  background-color: rgb(1, 1, 197);
  color: white;
  scale: 1.05;
}

.hero-image {
  width: 40%;
  height: 100%;
}

.img {
  height: 323px;
  width: 265px;
  overflow: hidden;
  margin: 0 0 10rem 0;
  box-shadow: 2rem 3rem 2rem 1rem silver;
  transition: 0.3s ease;
  border-radius: 0.3rem;
}

.hero-image img {
  height: 100%;
}

.hero-image .img:hover {
  scale: 1.05;
  box-shadow: 4rem 4rem 1.5rem 1rem silver;
}

.container-dark .img {
  box-shadow: 2rem 3rem 2rem 1rem rgb(40, 39, 39);
}

.container-dark .img:hover {
  box-shadow: 4rem 4rem 1.5rem 1rem rgb(40, 39, 39);
}

@media (max-width: 900px) {
  .home {
    flex-direction: column-reverse;
    margin: 0 0 5rem 0;
    text-align: center;
    height: 100vh;
  }

  .hero-div-wrapper {
    flex-direction: column-reverse;
    width: 55rem;
    margin: 10vh 0 0 0;
  }

  .hero-div {
    width: 100%;
    padding: 0 0;
    height: 38rem;
    align-items: center;
  }

  .hero-div h1 {
    width: 100%;
    font-size: 4rem;
    line-height: 5rem;
    margin: 0 0 1rem 0;
  }

  .hero-image {
    width: 100%;
    height: 25rem;
    margin: 0;
  }

  .img,
  .container-dark .img {
    margin: 0;
    height: 230px;
    width: 230px;
    border-radius: 50%;
    box-shadow: none;
    box-shadow: 0rem 0rem 0rem 0rem white;
  }

  .hero-image .img:hover,
  .container-dark .img:hover {
    box-shadow: 0rem 0rem 0rem 0rem white;
  }

  .hero-div p {
    margin: 0 0 2rem 0;
    width: 100%;
  }
}

@media (max-width: 550px) {
  .home {
    height: 72rem;
  }

  .hero-div-wrapper {
    width: 100%;
    padding: 0 1.5rem;
    margin: 3rem 0 0 0;
  }

  .hero-div p {
    margin: 0 0 1.5rem 0;
    opacity: 0.9;
  }
}
