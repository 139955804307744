.skills {
  flex-direction: column;
  /* border-top: 0.1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 0.1px solid rgba(0, 0, 0, 0.2); */
  padding: 8rem 0 5rem 0;
  /* background-color: #f6f2fa; */
  /* background-color: #d8d7dd; */
}

.container-dark .skills {
  /* background-color: #30334f; */
  color: white;
}

.skills h1 {
  font-weight: 600;
  font-size: 3rem;
}

.skills-images {
  width: 100%;
  margin: 2rem 0;
}

.skills-images img {
  height: 5rem;
  margin: 2rem 3rem;
}

@media (max-width: 550px) {
  .skills-images img {
    margin: 2rem;
    height: 4rem;
  }
}

@media (max-width: 400px) {
  .skills-images img {
    margin: 2rem 1rem;
    height: 4rem;
  }
}
